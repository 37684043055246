<template>
  <div class="detail">
    <Breadcrumb separator="->" :list="breadcrumbList"></Breadcrumb>
    <div class="detail-box">
      <div class="detail-title">友情链接</div>
      <div class="detail-text">
          <div class="member-list clearfix">
            <div class="member-item cursor" v-for="(item, index) of list" :key="index">
              <img :src="item.image" alt="" @click="itemClick(item.url)">
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  name: 'Friendship',
  data () {
    return {
      breadcrumbList: [
        { name: '首页', path: '/' },
        { name: '友情链接', path: '' }
      ],
      list: [
        { image: require('../../../assets/images/friendship/i.jpg'), url: 'https://www.mot.gov.cn/' },
        { image: require('../../../assets/images/friendship/c.jpg'), url: 'http://www.samr.gov.cn/' },
        { image: require('../../../assets/images/friendship/h.jpg'), url: 'http://www.caac.gov.cn/index.html' },
        // { image: require('../../../assets/images/friendship/m.jpg'), url: 'https://www.bipm.org/en/home' },
        // { image: require('../../../assets/images/friendship/n.jpg'), url: 'https://www.oiml.org' },
        { image: require('../../../assets/images/friendship/a.jpg'), url: 'http://www.sac.gov.cn/' },
        { image: require('../../../assets/images/friendship/b.jpg'), url: 'http://www.cnca.gov.cn/' },
        { image: require('../../../assets/images/friendship/f.jpg'), url: 'https://www.cnas.org.cn/' },
        { image: require('../../../assets/images/friendship/o.jpg'), url: 'http://std.samr.gov.cn/' },
        { image: require('../../../assets/images/friendship/l.jpg'), url: 'http://www.gb688.cn/bzgk/gb/' },
        { image: require('../../../assets/images/friendship/k.jpg'), url: 'http://jjg.spc.org.cn/resmea/view/index' }
        // { image: require('../../../assets/images/friendship/e.jpg'), url: 'https://www.cnis.ac.cn/pcindex/' },
        // { image: require('../../../assets/images/friendship/j.jpg'), url: 'https://www.nim.ac.cn/' },
        // { image: require('../../../assets/images/friendship/d.jpg'), url: 'http://www.china-cas.org/' },
        // { image: require('../../../assets/images/friendship/g.jpg'), url: 'http://www.castc.org.cn/' },
      ]
    }
  },
  created () {
  },
  mounted () {},
  methods: {
    itemClick (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.detail{
  padding-bottom: 30px;
}
.detail-box{
  padding: 40px 80px;
  min-height: 520px;
  background-color: #ffffff;
}
.detail-title{
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #E9E9E9;
  margin-bottom: 50px;
}
.detail-text{
  // padding: 0 35px;
}
.member-list{
  // padding: 30px 0 10px 10px;
}
.member-item{
  height: 70px;
  width: 280px;
  margin: 0 0 40px 50px;
  background-color: #fff;
  float: left;
}
.member-item img{
  display: block;
  height: 100%;
  width: 100%;
}
</style>
